import tw from "tailwind-styled-components";

export const NavWrapper = tw.div`
 flex  items-center pt-5 justify-between lg:pt-6 h-20 pb-4 border-b
`;
export const Holder = tw.div`
 md:ml-[90px] w-[170px] h-[31px] pt-2   lg:ml-12 lg:pt-[0]
`;
export const UlWrapper = tw.div`
 hidden lg:block
`;
export const ButtonWrapper = tw.div`
 hidden lg:block
`;
export const MenuWrapper = tw.div`
 flex justify-center ml-[10px] lg:ml-[20px] lg:hidden mt-2 md:mt-0
`;
export const CartAndAvatar = tw.div`
 lg:flex items-center lg:mr-[100px] hidden
`;
