import { useEffect } from "react";

function TermsAndConditions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className=" px-7 lg:px-0 lg:mx-[65px] my-[35px] w-full lg:w-[60%] ">
      <div className="text-3xl font-extrabold text-black font-montserrat ">
        Terms & Conditions
      </div>
      <div className=" text-xl font-Montserrat my-4">
        1. Acceptance of terms of use and amendments
      </div>
      <div className=" text-gray-500 text-sm font-Montserrat">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum
        repudiandae aliquam et omnis, quis libero esse eligendi impedit non
        cumque hic eveniet quasi animi nostrum, iusto quod ad maxime in?
        Blanditiis fugiat aperiam dolorum possimus doloribus soluta repellat
        eaque nisi placeat minima, sit sequi ab! Lorem ipsum dolor sit amet
        consectetur adipisicing elit. Dolorum, laborum?
      </div>
      <div className=" text-xl font-Montserrat my-4">
        2. The site editor's service
      </div>
      <div className=" text-gray-500 text-sm font-Montserrat">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum
        repudiandae aliquam et omnis, quis libero esse eligendi impedit non
        cumque hic eveniet quasi animi nostrum, iusto quod ad maxime in?
        Blanditiis fugiat aperiam dolorum possimus doloribus soluta repellat
        eaque nisi placeat minima, sit sequi ab! Lorem ipsum dolor sit amet
        consectetur adipisicing elit. Dolorum, laborum?
      </div>
      <div className=" text-xl font-Montserrat my-4">
        3. Your restrictions and registration obligations
      </div>
      <div className=" text-gray-500 text-sm font-Montserrat">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum
        repudiandae aliquam et omnis, quis libero esse eligendi impedit non
        cumque hic eveniet quasi animi nostrum, iusto quod ad maxime in?
        Blanditiis fugiat aperiam dolorum possimus doloribus soluta repellat
        eaque nisi placeat minima, sit sequi ab! Lorem ipsum dolor sit amet
        consectetur adipisicing elit. Dolorum, laborum?
      </div>
      <div className=" text-xl font-Montserrat my-4">4. Privacy Policy</div>
      <div className=" text-gray-500 text-sm font-Montserrat">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum
        repudiandae aliquam et omnis, quis libero esse eligendi impedit non
        cumque hic eveniet quasi animi nostrum,
      </div>
      <div className=" text-xl font-Montserrat my-4 ">
        5. Registration and Password
      </div>
      <div className=" text-gray-500 text-sm font-Montserrat">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum
        repudiandae aliquam et omnis, quis libero esse eligendi impedit non
        cumque
      </div>
      <div className=" text-xl font-Montserrat my-4">6. Your Conduct</div>
      <div className=" text-gray-500 text-sm font-Montserrat">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum
        repudiandae aliquam et omnis, quis libero esse eligendi impedit non
        cumque hic eveniet quasi animi nostrum, iusto quod ad maxime in?
        Blanditiis fugiat aperiam dolorum possimus doloribus soluta repellat
        eaque nisi placeat minima, sit sequi ab! Lorem ipsum dolor sit amet
        consectetur adipisicing elit. Dolorum, laborum? Lorem ipsum dolor sit
        amet consectetur adipisicing elit. Earum, commodi! <br />
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas quia
        alias, consequuntur quaerat architecto beatae quos dolore ipsum ut
        doloremque, sunt incidunt unde quibusdam rerum error veniam voluptate
        explicabo excepturi. Numquam nam eius alias dignissimos, hic
        necessitatibus obcaecati, culpa, beatae voluptatibus ipsam rem
        reprehenderit corrupti!
      </div>
    </div>
  );
}

export default TermsAndConditions;
