import tw from "tailwind-styled-components";

export const ParentContainer = tw.div`
 lg:mx-28 my-16 px-5 mx-5  py-8 lg:py-0 
`;
export const Heading = tw.div`
 font-montserrat text-xl font-extrabold p-10 pt-0
`;
export const AvatarHolder = tw.div`
 my-5
`;
export const InputHeading = tw.div`
 text-lg font-montserrat font-medium text-left my-4
`;
export const Display = tw.div`
 py-2 lg:w-[380px] bg-gray-100 rounded-md text-left pl-3 text-black font-montserrat font-light 
`;
export const ChangePassword = tw.div`
 mt-12 mb-2  text-lg font-montserrat font-extrabold text-left 
`;
export const ButtonHolder = tw.div`
 w-full
`;
